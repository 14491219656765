import React from "react";
import "./TokenDistribution.css";
import Coin3d from "../../assets/img/SpinCoin.gif";
import Graph1 from "../../assets/img/Token-circulation.png";
import Graph2 from "../../assets/img/Token-allocation.png";
import teamFinance from "../../assets/img/team-finance.png"
import teamArrow from "../../assets/img/team-arrow.png"

const TokenDistribution = ({ buyTokenRef }) => {
  const scrollToSection = () => {
    const section = document.getElementById('buy-tokens');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <div className="section-space container-width">
      <h1 className="play-heading alter">Token Distribution</h1>
      <p className="buy-desc">
        The BLUE token is a utility token. It is the unit of value that The Blue Network
        creates to “self-govern its business model and empower its users to
        interact with its products, and transact among network participants,
        while facilitating the distribution and sharing of rewards and benefits
        to all its stakeholders.
      </p>
      <div className="graph-section">
        <div className="circulation">
          <div className="coin-name">
            <img src={Coin3d} className="token-logo" alt="" />
            <p>Name: Blue Social Token</p>
            <p>Symbol: BLUE</p>
            <p>Total Supply: 2,500,000,000 BLUE</p>
          </div>
          <div
            className="circulation-graph"
            data-aos="fade-up"
            data-aos-delay="200"
            data-aos-duration="1500"
          >
            <img src={Graph1} alt="" />
          </div>
        </div>
        <div
          className="circle-graph"
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-duration="1500"
        >
          <img src={Graph2} alt="" />
        </div>
      </div>
      <div className="buy-btn altwr team-finance">
        <span>
          <a target="_blank" href="https://www.team.finance/view-coin/0x744952c666990ddba159387a9b9e668e4e72682e?name=Blue%20Social%20Token&symbol=BLUE&chainid=0x2105">
            <img src={teamFinance} />
          </a>
          <img className="arrow" src={teamArrow} />
          <p className="text">Tokens locked in vesting contracts</p>
        </span>
      </div>
      
    </div>
  );
};

export default TokenDistribution;
