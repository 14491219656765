export const TOKEN_ADDRESS = "0x744952C666990dDba159387a9B9e668e4e72682E";
export const WALLET_TO_TRACK = "0xc81f7348e1d7B0cC85767165cF0B65365c5eAB3D";
export const NFT_TOKEN_ADDRESS = "0x26642998c97138f9D14788B86be7cA83aB63B6F9"
export const ERC20_ABI = ["function balanceOf(address owner) view returns (uint256)"];
export const TOKEN_SUPPLY = 2500000000;




export const BASE_RPC = 'https://base-mainnet.g.alchemy.com/v2/9YzNPp7po2ZfnsIckNTabu13oi3V7uke'
export const BASE_KEY = '9YzNPp7po2ZfnsIckNTabu13oi3V7uke'

