import React, { useState } from "react";
import "./Welcome.css";
import WelcomeBg from "../../assets/img/bg-video.png";
const Welcome = () => {
  const [toggleVideo, settoggleVideo] = useState(false);
  return (
    <div className="section-space container-width">
      <h1 className="platfrom-heading ">Watch Onchain Summer Demo</h1>
      <br/>
      <div className="video">
        <iframe
          className="welcome-bg video iframe"
          src="https://youtube.com/embed/thPrNv1IYBU"
          title="Watch Onchain Summer Demo"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  );
};

export default Welcome;
