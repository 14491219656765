import "./assets/css/styles.css";
import Banner from "./components/Banner/Banner";
// import Buytoken from "./components/Buytoken/Buytoken";
import HowToPlay from "./components/HowToPlay/HowToPlay";
import Platfroms from "./components/Platforms/Platfroms";
// import Privatesale from "./components/Privatesale/Privatesale";
import Quote from "./components/Quote/Quote";
import Reviews from "./components/Reviews/Reviews";
import Roadmap from "./components/Roadmap/Roadmap";
import ScocialToken from "./components/SocialToken/ScocialToken";
import BlueProtocol from "./components/SocialToken/BlueProtocol";
import Team from "./components/Team/Team";
import TokenDistribution from "./components/TokenDistribution/TokenDistribution";
import Welcome from "./components/Welcome/Welcome";
import Footer from "./layout/Footer/Footer";
import Header from "./layout/Header/Header";
import Problem from "./components/ProblemSolution/Problem";
import LetBlue from "./components/ProblemSolution/LetBlue";
import ThirdwebCopy from "./components/SmartThird/ThirdwebCopy";
import SmartWalletCopy from "./components/SmartThird/SmartWalletCopy";
import { useRef } from "react";
import FeatureSection from "./components/Feature";
import "./App.css"
import BuyBlueToken from "./components/BuyBlueToken";


function HomePage() {
  const buyTokenRef = useRef(null);

  return (
    <div className="App">
      <Header />
      <Banner buyTokenRef={buyTokenRef} />
      <Platfroms />
      {/* <Countdown /> */}
      {/* <Privatesale /> */}
      {/* <Buytoken ref={buyTokenRef} /> */}
      <Problem />
      <LetBlue />
      <Welcome />
      {/* <PublicSale /> */}
      {/* <ThirdwebPay /> */}
      <BuyBlueToken />
      <HowToPlay />
      <FeatureSection />
      <ScocialToken buyTokenRef={buyTokenRef} />
      <SmartWalletCopy />
      <ThirdwebCopy />
      <BlueProtocol buyTokenRef={buyTokenRef} />
      <TokenDistribution buyTokenRef={buyTokenRef} />
      <Team />
      <Quote buyTokenRef={buyTokenRef} />
      <Reviews />
      <Roadmap />
      <Footer />
    </div>
  );
}

export default HomePage;
