import React from "react";
import "aos/dist/aos.css";
import AOS from "aos";
import Step from "./Step";
import Mbl1 from "../../assets/img/iphone-1.png";
import Mbl2 from "../../assets/img/mbl2.png";
import Mbl3 from "../../assets/img/mbl3.png";
import Side1 from "../../assets/img/side1.png";
import Side2 from "../../assets/img/side2.png";
import Side3 from "../../assets/img//Side3.png";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Autoplay } from 'swiper/modules';
import "./HowToPlay.css";



AOS.init();

const HowToPlay = () => {

  return (
    <div className="section-space howPlay container-width">
      <h1 className="play-heading">How to earn</h1>
      <p className="instructions">
        Blue Social creates an experience for you at any
      </p>
      <p className="instructions"> public social event or gathering</p>

      <div className="desktop-only">
        <Step  
          step={1} phone={Mbl1} side={Side1} reverse={false}
          content={"Discover people in proximity and Break The Ice to notify them you want to meet."} />
        
        <Step  
          step={2} phone={Mbl2} side={Side2} reverse={true}
          highlight={"No Internet or Wi-Fi required."}
          content={"When two users agree to be social — Blue uses Bluetooth directional & distance to find each other."} />
      
        <Step  
          step={3} phone={Mbl3} side={Side3} reverse={false}
          highlight={"Once authenticated, both users will be rewarded."}
          content={"Be social for at least 30 seconds to authenticate the Proof of Interaction."} />
      
      </div>


      <div className="mobile-only">

        <Swiper
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          loop={true}
          modules={[Pagination, Autoplay]}
          className="mySwiper">

            <SwiperSlide>
              <Step  
                step={1} phone={Mbl1} side={Side1} reverse={false} noAnimate={true}
                content={"Discover people in proximity and Break The Ice which notifies them you want to meet."} />
            </SwiperSlide>

            <SwiperSlide>
              <Step
                small={true}
                highlight={"No Internet or Wifi required."}
                step={2} phone={Mbl2} side={Side2} reverse={true} noAnimate={true}
                content={"When two users agree to be social — Blue uses Bluetooth directional & distance to find each other."} />
            </SwiperSlide>

          <SwiperSlide>
            <Step 
              small={true} 
              step={3} phone={Mbl3} side={Side3} reverse={false} noAnimate={true}
              highlight={"Once authenticated, both users will be rewarded."}
              content={"Be social for at least 30 seconds to authenticate the Proof of Interaction."} />
          </SwiperSlide>

        </Swiper>
      </div>  

    </div>
  );
};

export default HowToPlay;
