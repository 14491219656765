import "./styles.css"
import logo from "../assets/img/Big-Logo.png";
import Header from "../layout/Header/Header";
import Step from "./step";
import {
    useConnectionStatus,
    ConnectWallet,
    useSetIsWalletModalOpen,
    useDisconnect,
    useContractRead, 
    useContract, 
    useContractWrite,
    useTokenBalance,
    useAddress,
    useChainId,
    useSwitchChain,
    useContractEvents
} from "@thirdweb-dev/react";
import { Base } from "@thirdweb-dev/chains";
import { useEffect, useState } from "react";
import Modal from "react-modal"
import ThirdwebPay from "../components/ThirdwebPay"
import { NFT_TOKEN_ADDRESS, TOKEN_ADDRESS } from "../CONSTANTS";
import axios from "axios";
import contractAbi from "./abi.json";

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
};
  
  
const DISCORD_AUTH_URL = "https://discord.com/oauth2/authorize?client_id=1272959577360695392&response_type=code&redirect_uri=https%3A%2F%2Fearly-access-be.onrender.com%2Fauth%2Fdiscord%2Fcallback&scope=identify+guilds.join+email+guilds"
const ENDPOINT = "https://early-access-be.onrender.com/auth/save"

export default function EarlyAccess () {

    const [isBuyModalOpen, setIsBuyModalOpen] = useState(false)

    const [tokenId, setTokenID] = useState(null)

    const [transactionHash, setTransactionHash] = useState(null)

    const [iosLoading, setIosLoading] = useState(false)

    const [currentStep, setCurrentStep] = useState(1)

    const disconnect = useDisconnect()

    const address = useAddress();
    
    const chainId = useChainId();

    const switchChain = useSwitchChain()

    const { contract: blueTokenContract } = useContract(TOKEN_ADDRESS, "token");

    const blueInfo = useTokenBalance(blueTokenContract, address);

    const { data: contract } = useContract(NFT_TOKEN_ADDRESS, contractAbi);

    const { data: userNFTBalance } = useContractRead(contract, "balanceOf", [address]);

    const { data: events } = useContractEvents(contract, "Transfer", {
        queryFilter: {
            filters: {
                to: address,
            },
        },
        subscribe: true, // Subscribe to new events
    });

    const { mutateAsync: mint, isLoading, error,  } = useContractWrite(contract, "safeMint");

    const [discord, setDiscord] = useState(JSON.parse(localStorage.getItem("discord")))

    const openWalletModal = useSetIsWalletModalOpen()

    const connectionStatus = useConnectionStatus();

    useEffect(() => {
        if (connectionStatus === "connected") {
            if (chainId == Base.chainId) {
                if (discord) {
                    if (userNFTBalance > 0) {
                        setCurrentStep(5)
                    } else {
                        setCurrentStep(4)
                    }
                } else {
                    setCurrentStep(3)
                }   
            } else {
                setCurrentStep(2)
            }
        } else {
            setCurrentStep(1)
        }
    }, [connectionStatus, userNFTBalance, discord, chainId, Base.chainId])


    useEffect(() => { 
        if (events?.length > 0) {
            const event = events[0]
            setTransactionHash(event?.transaction?.transactionHash)
            setTokenID(event?.data?.tokenId?.toString())
        }
    }, [])
 
    async function handleConnect() {
      openWalletModal(true)
    }

    function signInWithDiscord (){
        window.location.href = DISCORD_AUTH_URL
    }

    async function reset() {
        localStorage.removeItem("discord")
        setDiscord(null)
        await disconnect()
    }


    async function handleMint() {
        mint({args: []})
    }

    async function saveUserInfo() {
        setIosLoading(true)
        try {
            await axios.post(ENDPOINT, {
                address,
                id: discord.id,
                email: discord.email,
                username: discord.username,
                balance: blueInfo?.data?.displayValue,
                tokenId,
                transactionHash
            })
            reset()
            window.open("https://testflight.apple.com/join/RlfwnoC0")
        } catch(e) {
            console.error(e)
        } finally {
            setIosLoading(false)
        }
    }

    function showModal () {
        setIsBuyModalOpen(true)
    }

    return (
        <>

            <div>

                <Header showModal={showModal} />

                <div className="early-access">

                    <div className="logo-section">
                        <img width={"274px"} height={"auto"} src={logo}  alt="logo" />
                        <div className="connect">
                            <ConnectWallet />
                        </div>
                    </div>

                    <p className="info-text">
                        To get Early Access to the Blue Social Web3 Beta, we require you to hold at least 100 BLUE tokens <br/>
                        and mint the Early access NFT. The tokens you earn on testnet will be exchanged 1:1 for BLUE tokens on mainnet. <br /> 
                        Only available on iOS. <br />

                        <i> *Android coming soon! </i>
                    </p>

                    <div className="step-container">

                        <Step 
                            isCurrentStep={currentStep === 1}
                            onClick={handleConnect}
                            step={1} 
                            description={"CONNECT WALLET"} 
                            done={connectionStatus === "connected"} 
                            />

                        <Step 
                            onClick={() => switchChain(Base.chainId)}
                            isCurrentStep={currentStep === 2}
                            step={2} 
                            description={"SWITCH TO BASE MAINNET"} 
                            done={chainId === Base.chainId} 
                            action={"SWITCH CHAIN"}
                            /> 

                        <Step
                            isCurrentStep={currentStep === 3}
                            onClick={signInWithDiscord}
                            step={3} 
                            description={"CONNECT DISCORD"} 
                            done={discord}
                            action={"CONNECT"}
                            />

                        <Step 
                            isCurrentStep={currentStep === 4}
                            onClick={handleMint}
                            step={4} 
                            description={"MINT"} 
                            isLoading={isLoading}
                            done={userNFTBalance > 0} 
                            mint={true}
                            canMint={Number(blueInfo?.data?.displayValue) >= 100}
                            action={"MINT"} />

                        <Step
                            isCurrentStep={currentStep === 5}
                            onClick={saveUserInfo}
                            step={5} 
                            description={"DOWNLOAD BETA APP"} 
                            done={false}  
                            last={true}
                            isLoading={iosLoading}
                            ios={saveUserInfo}
                            action={"DONE"}
                            />

                    </div>

                    <p onClick={reset} className="restart">RESTART</p>

                </div>

            </div>

            { 
                isBuyModalOpen  &&
                    <div className="modal-wrapper">
                        <div onClick={() => setIsBuyModalOpen(false)} className="overlay"></div>
                        <div className="content">
                            <ThirdwebPay />
                        </div>
                    </div>
            }

        </>
    )
}
