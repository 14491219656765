import { useState } from "react"
import earlyAccessNft from "./early-access-nft.gif"
import TestflightBtn from "./test-flight-btn"
import appleIcon from "./apple.png"
import androidIcon from "./android.png"

export default function Step ({step, last, isLoading, action, mint, ios, canMint, isCurrentStep, description, done, onClick}) {

    const [active, setActive] = useState(false)

    const handleClick = () => {
        if (done || !isCurrentStep) return
        onClick()
    }

    return (
        <>
            <div onMouseEnter={() => setActive(true)} onMouseLeave={() => setActive(false)} className={"step "}>
                <button className={`number ${active | isCurrentStep ? "active" : ""}`}>{step}.</button>
                <button onClick={handleClick} className={`btn ${active | isCurrentStep ? "active" : ""}`}>
                    <p>{description}</p>
                    { done && <p className="done">DONE</p> }
                </button>
            </div>
            { 
                isCurrentStep && !last &&
                    <div>
                        {
                           mint && (
                                <div className="nft-section">
                                    <img src={earlyAccessNft} width={"240px"} />
                                    {! canMint && <p className="balance" style={{color: "red"}}>You must have a balance of at least 100 BLUE tokens</p> }
                                </div>
                            )
                        }
                        {
                            isLoading && (
                                <button disabled={isLoading} className="btn next">
                                    Loading Please Wait...
                                </button>
                            )
                        }
                        { !isLoading &&
                            <button disabled={mint && !canMint} className="btn next" onClick={handleClick}>
                                { action || "Next"  }
                            </button>
                        }
                    </div>
            }
            {
                (last && isCurrentStep) && (
                    <div className="last">
                        {
                            isLoading ? 
                                <p style={{color: "white"}}>Loading Please Wait...</p>: (
                                    <>
                                        <TestflightBtn icon={appleIcon} content={"TestFlight"} onClick={ios} />
                                        <TestflightBtn icon={androidIcon} content={"Comming Soon"} disabled={true} onClick={ios} />
                                    </>
                                )
                        }
                    </div>
                )
            }
        </>
    )
} 