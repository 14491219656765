import React from "react";
import "./Problem.css";
import Prob1 from "../../assets/img/problem1.png";
const LetBlue = ({ buyTokenRef }) => {

  const scrollToSection = () => {
    const section = document.getElementById('buy-tokens');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div
      className="main-banner container-width change-orientation"
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <div className="banner-img-2">
        <img className="blue-image-2" src={Prob1} alt="" />
      </div>
      <div className="banner-text">
        <h1>
          Let Blue Introduce You®
        </h1>
        <p>
        At Blue Social, we harness technology for social good by gamifying 
        in-person interactions. Our platform rewards you with BLUE tokens 
        for real-life conversations, verified by our Bluetooth 
        Proof-of-Interaction™ (POI) protocol, ensuring genuine 
        face-to-face engagement. The more you socialize IRL, 
        the more you earn with our Socialize-to-Earn™ system.
        </p>
        <div className="btn-container">
          <a href="https://web3.blue.social/early-access" target="_blank" onClick={scrollToSection} className="blue-btn">
            Join Beta Program
          </a>
        </div>
      </div>
    </div>
  );
};

export default LetBlue;
