import React from "react";
import "./SmartThird.css";
import Prob0 from "../../assets/img/SmartWallet.png";
const SmartWalletCopy = ({ buyTokenRef }) => {

  const scrollToSection = () => {
    const section = document.getElementById('buy-tokens');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div
      className="main-banner intergration-container container-width"
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <div className="banner-img">
        <img src={Prob0} alt="" />
      </div>
      <div className="banner-text">
        <h1>
          Coinbase Smart Wallet
        </h1>Coming soon...
        <p>
        The easiest and fastest way to bring our Web2 users
         onchain in seconds with no seed phrases, passwords, or
          third party app installs.
          <br/>
          <br/>
          We also integrated Paymaster to sponsor user gas fees.
        </p>
      </div>
    </div>
  );
};

export default SmartWalletCopy;
