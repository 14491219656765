import React from "react";
import "./ScocialToken.css";
import BProtocol from "../../assets/img/BlueProtocol.png";
const BlueProtcol = ({ buyTokenRef }) => {
  const scrollToSection = () => {
    const section = document.getElementById("buy-tokens");
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="section-space container-width">
      <div className="buy-btn">
        {/* <a onClick={scrollToSection} className="blue-btn">
          JOIN iOS BETA PROGRAM
        </a> */}
      </div>
      <br />
      <br />
      <br />
      <br />
      <h1 className="blueCenter">The Blue Network Protocol</h1>
      <br />
      <br />
      <p className="buy-desc">
        The Blue network protocol turns your smartphone into a two-way Bluetooth
        beacon that connects <br />
        with other IoT devices. Advertise and receive data completely offline
        through secure encrypted <br />
        Bluetooth transactions. This can be used to discover nearby users,
        payments and more!
      </p>
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="indiv-buy">
        <img src={BProtocol} alt="" />
      </div>
    </div>
  );
};

export default BlueProtcol;
