const FeatureCard = ({icon, text}) => {

    return (
        <div>
            <img width={"100px"} height={"100px"} src={icon} />
            <p>{text}</p>
        </div>
    )
}

export default FeatureCard