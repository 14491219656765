import React from "react";
import "./Banner.css";
import BannerImg from "../../assets/img/HeroGif.gif";
import winnerBadge from "../../assets/img/winner-badge.png"
import BlueSocialConnect from "../../assets/img/Blue-Social-Connect.png"


const Banner = ({ buyTokenRef }) => {
  const scrollToSection = () => {
    const section = document.getElementById("buy-tokens");
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div
      className="main-banner container-width"
      data-aos="fade-up"
      data-aos-duration="1000">
        
      <div className="banner-text" style={{display: "block", flexBasis: "500px"}}>

        <div style={{marginTop: "6%"}}>

          <img className="logo" width={"116px"} height={"116px"} src="/blue-logo.png" alt="blue" />

          <h1 className="banner-header">It Pays to Be Social™</h1>

          <p>
            Welcome to the future of networking. At Blue Social, <br /> we make meeting people in real life fun and rewarding. 
            <br />
            <br />
            Think Pokémon GO but for making new friends.
          </p>
          <div className="btn-container">
            <a href="#buy-token"  onClick={scrollToSection} className="blue-btn">
              Buy BLUE Token
            </a>
            <a
              href="https://whitepaper.blue.social"
              target="_blank"
              className="white-paper-btn">
              Whitepaper
            </a>
          </div>
          {/* <a href="https://base.mirror.xyz/p_A3ZxaBUEcpWwTf9_Yg-jnlOro8m8Ypc8LEDnA_ZRk" target="_blank" className="we-won">
            <div><img height={42} width={28} src={winnerBadge} /></div>
            <p>GAMING with thirdweb: 🏆 Base Buildathon 2024 Winner </p>
          </a>  */}
        </div>

      </div>

      <div className="header-banner-img">
        <img src={BannerImg} alt="" />
      </div>

    </div>
  );
};

export default Banner;
