import React from "react";
import "./SmartThird.css";
import Prob1 from "../../assets/img/Thirdweb.png";


const ThirdWebCopy = ({ buyTokenRef }) => {

  const scrollToSection = () => {
    const section = document.getElementById('buy-tokens');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div
      className="main-banner container-width"
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <div className="banner-text">
        <h1>
          ThirdWeb Integratation
        </h1>
        <p>
          Blue Social leverages ThirdWeb SDK's to deploy smart
          contracts to the BASE Mainnet and  Engine to create 
          a seamless user experience.
        </p>
        <div className="btn-container">
          <a onClick={scrollToSection} href="https://web3.blue.social/early-access" target="_blank" className="blue-btn">
            JOIN BETA PROGRAM
          </a>
        </div>
      </div>
      <div className="banner-img">
        <img src={Prob1} alt="" />
      </div>
    </div>
  );
};

export default ThirdWebCopy;
