import "./style.css"
import FeatureCard from "./FeatureCard";
import discoverImage from "./../../assets/img/feature/Discover Users.png";
import breakImage from "../../assets/img/feature/Break the Ice.png"
import premiumImage from "../../assets/img/feature/Premium Features.png"
import marketImage from "../../assets/img/feature/Marketplace.png"
import businessImage from "../../assets/img/feature/Mint NFT Cards.png"
import tipImage from "../../assets/img/feature/Payments.png"
import storeImage from "../../assets/img/feature/Store.png"


const FeatureSection = () => {


    return (
        <section  className="container-width feature">

            <button data-aos="fade-up" data-aos-duration="1000" className="gradient-btn">Blue social tokens [BLUE]</button>

            <p data-aos="fade-up" data-aos-duration="1000">
                The network is powered by the BLUE utility token that is built
                with the Ethereum ERC-20 smart contract standard.
            </p>

            <div className="items" data-aos="fade-up" data-aos-duration="1000">

                <FeatureCard icon={discoverImage} text="Discover" />

                <FeatureCard icon={breakImage} text="Break the Ice" />

                <FeatureCard icon={premiumImage} text="Premium Features" />

                <FeatureCard icon={businessImage} text="Scan Business Card" />

                <FeatureCard icon={marketImage} text="Market Place" />

                <FeatureCard icon={tipImage} text="Social Tip" />

            </div>

            <h2 data-aos="fade-up" data-aos-duration="1000">Beacon Proximity Advertising</h2>

            <h3 data-aos="fade-up" data-aos-duration="1000">$233B Market by 2033</h3>

            <p data-aos="fade-up" data-aos-duration="1000" className="market">
                Blue Social takes proximity marketing to the next level by allowing businesses and brands to target users that opt-in with personalized offers and advertisements when they walk by using Bluetooth beacons, encouraging immediate actions and rewarding them with tokens.
            </p>

            <button data-aos="fade-up" data-aos-duration="1000" className="comming"> Comming 2025 </button>

            <img data-aos="fade-up" data-aos-duration="1000" width={"100%"} height={"auto"} src={storeImage} alt="" />
        
        </section>
    )
}


export default FeatureSection