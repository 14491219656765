import React from "react";
import "./Footer.css";
import DISCORD from "../../assets/img/discord.png";
import TELEGRAM from "../../assets/img/telegram.png";
import BASE from "../../assets/img/base-logo.png";
import INSTAGRAM from "../../assets/img/instagram.png";
import TWITTER from "../../assets/img/x.png";
import THREADS from "../../assets/img/threads.png";
import WHITEPAPER from "../../assets/img/white-paper.png";
import { TOKEN_ADDRESS } from "../../CONSTANTS";


const Footer = () => {
  return (
    <div className="section-space">
      <div className="sub-section">
        <div className="container-width">
          <h1 className="sub-head">
           Stay in the Loop on Blue Social
          </h1>
          <p className="sub-para">
            Official links
          </p>
          <div className="social-links">
            <div className="social">
              <a href="https://discord.gg/U6gFDX7Q4z" target="_blank">
                <img src={DISCORD} alt="Discord" />
                <p className="sub-social">Discord</p>
              </a>
            </div>
            <div className="social">
              <a href="https://t.me/Blue_Social" target="_blank">
                <img src={TELEGRAM} alt="Telegram" />
                <p className="sub-social">Telegram</p>
              </a>
            </div>
            <div className="social">
              <a href="https://www.instagram.com/blue_social/?hl=en" target="_blank">
                <img src={INSTAGRAM} alt="Instagram" />
                <p className="sub-social">Instagram</p>
              </a>
            </div>
            <div className="social">
              <a href="https://www.threads.net/@blue_social" target="_blank" >
                <img src={THREADS} alt="Threads" />
                <p className="sub-social">Threads</p>
              </a>
            </div>
            <div className="social">
              <a href="https://twitter.com/bluesocialapp?lang=en" target="_blank" >
                <img src={TWITTER} alt="Twitter" />
                <p className="sub-social">X</p>
              </a>
            </div>
            <div className="social">
              <a href="https://whitepaper.blue.social/" target="_blank" >
                <img src={WHITEPAPER} alt="Website" />
                <p className="sub-social">Whitepaper</p>
              </a>
            </div>
            <div className="social-eth-address" onClick={() =>  navigator.clipboard.writeText(`${TOKEN_ADDRESS}`)}>
              <a>
                <p className="sub-social-eth">
                <img src={BASE} alt="BASE" />
                  BLUE Token: {TOKEN_ADDRESS}
                </p>
              </a>
            </div>
          </div>
          {/* <p className="sub-para">https://blue.social</p> */}
        </div>
      </div>
    </div>
  );
};

export default Footer;
