import { ThirdwebProvider } from 'thirdweb/react';
import EmbeddePay from './EmbeddedPay';
import './style.css';

export default function ThirdwebPay() {
  return (
    <ThirdwebProvider>
      <section className='thirdweb-pay'>
        <EmbeddePay />
      </section>
    </ThirdwebProvider>
  );
}
