import React from "react";


const Step = ({step, highlight, reverse, phone, side, content, noAnimate, small}) => {

  let animate = {
    "data-aos-delay": "100",
    "data-aos-offset":  "150",
    "data-aos-duration":  "1500",
    "data-aos": reverse ? "fade-right" : "fade-left"
  }

  if (noAnimate) animate = {}


  return (
    <div className={"flex-play"} {...animate}>

        {
          reverse &&  <img draggable="false" src={side} className="side-img" alt="" />
        }

        <div className="roadmap-card">

          <h3>Step {step}</h3>

          <div className="roadmap-list">
            <p>{content}</p>

            <p className="highlight">{highlight}</p>
          </div>

          <img className={`phone   ${small ? "phone-2" : ""}`} src={phone} alt="" />

        </div>

        {
          !reverse && <img draggable="false" src={side} className="side-img" alt="" /> 
        }

    </div>
  );
};

export default Step;
