import React from "react";
import "./ScocialToken.css";
import Buy1 from "../../assets/img/free-tokens.png";
import Base from "../../assets/img/Base.png";
import Buy2 from "../../assets/img/buy2.svg";
import Buy3 from "../../assets/img/buy3.svg";
import Buy4 from "../../assets/img/buy4.svg";
import Buy5 from "../../assets/img/buy5.svg";
import Buy6 from "../../assets/img/buy6.svg";
const ScocialToken = ({ buyTokenRef }) => {
  const scrollToSection = () => {
    const section = document.getElementById("buy-tokens");
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="section-space container-width">
      <div className="buy-btn">
        <a onClick={scrollToSection} href="https://web3.blue.social/early-access" target="_blank" className="blue-btn">
          JOIN BETA PROGRAM
        </a>
      </div>
      <br />
      <br />
      <br />
      <br />
      <div className="indiv-buy">
        <img src={Base} alt="" />
      </div>
      <br />
      <br />
      <br />
      <br />
      <h1 className="blueCenter">Blue social is Built on BASE</h1>
      <br />
      <br />
      <div style={{display: "flex", justifyContent: "center"}}>
        <p style={{maxWidth: 760}} className="buy-desc">
          Blue Social benefits from Base's robust infrastructure, which is built 
          as an Ethereum L2, providing the security, stability, and scalability 
          needed to power our onchain app. With Base, Blue Social can confidently 
          deploy any EVM codebase, seamlessly onboarding users and assets from 
          Ethereum L1, Coinbase, and other interoperable chains.
        </p>
      </div>
      <br />
      <br />
      <br />
      <br />

      <br />
      {/* <div className="icons-buy"> */}
      {/* <div className="indiv-buy">
          <img src={Buy1} alt="" />
        </div> */}

      {/* <div className="buy-btn">
        <a onClick={() => window.open("https://forms.gle/CxNgZZTYJDSeogyx7")} className="blue-btn">
          Claim Free Tokens
        </a>
      </div> */}
      {/* <div className="indiv-buy">
          <img src={Buy2} alt="" />
          <p>Break the Ice</p>
        </div>
        <div className="indiv-buy">
          <img src={Buy3} alt="" />
          <p>Premium Features</p>
        </div>
        <div className="indiv-buy">
          <img src={Buy4} alt="" />
          <p>Mint NFT Cards</p>
        </div>
        <div className="indiv-buy">
          <img src={Buy5} alt="" />
          <p>Marketplace</p>
        </div>
        <div className="indiv-buy">
          <img src={Buy6} alt="" />
          <p>Payments</p>
        </div> */}
      {/* </div> */}
    </div>
  );
};

export default ScocialToken;
