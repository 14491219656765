import React, { useEffect, useState } from "react";
import "./Roadmap.css";
import Roadmapimg from "../../assets/img/roadmap.svg";
const Roadmap = () => {
  const [toggleCard, settoggleCard] = useState(false);
  useEffect(() => {
    let mediaQuery = window.matchMedia("(min-width: 600px)");
    if (mediaQuery.matches === true) {
      settoggleCard(true);
    } else {
      settoggleCard(false);
    }
  }, []);
  return (
    <div className="container-width section-space">
      <h1 className="platfrom-heading">Blue Development Roadmap</h1>
      <div className="main-roadmap">
        <div className="col1">
          <div className="roadmap-card">
            <h3>Phase 1: 2019-2021</h3>
            <div className="roadmap-list">
              <ul>
                <li>Developed the Blue(tooth) Protocol</li>
                <li>First movers to integrate NFC Technology </li>
                <li>Raised $1M from Draper Associates</li>
                <li>Successful crowdfund from 4,000 investors</li>
                <li>Generated $1,000,000+ in revenue</li>
                <li>Developed Bluetooth decentralized contact tracing technology
                  </li>
              </ul>
            </div>
          </div>
          {toggleCard ? (
            <div className="roadmap-card card3 ">
              <h3>Phase 3: 2023-2025</h3>
              <div className="roadmap-list">
                <ul>
                  <li>Partnered with 65+ college organizations</li>
                  <li>Participate in BASE Buildathon</li>
                  <li>Developed/Integrated Smart contracts</li>
                  <li>Smart Contract Audits</li>
                  <li>Launch on BASE Mainnet</li>
                </ul>
              </div>
            </div>
          ) : (
            <div className="roadmap-card card2">
              <h3>Phase 2: 2021-2023</h3>
              <div className="roadmap-list">
                <ul>
                  <li>Bluetooth Proof of Interaction™ Protocol</li>
                  <li>Network of 75,000 users worldwide </li>
                  <li>Reached 50M people digital marketing</li>
                  <li>Survived the Global Pandemic (Covid-19)</li>
                  <li>Reached 5,000,000 Social Interactions</li>
                </ul>
              </div>
            </div>
          )}
        </div>
        <div className="col2">
          <img src={Roadmapimg} alt="" />
        </div>
        <div className="col3">
          {toggleCard ? (
            <div className="roadmap-card card2">
              <h3>Phase 2: 2021-2023</h3>
              <div className="roadmap-list">
                <ul>
                  <li>Bluetooth Proof of Interaction™ Protocol</li>
                  <li>Network of 75,000 users worldwide </li>
                  <li>Reached 50M people digital marketing</li>
                  <li>Survived the Global Pandemic (Covid-19)</li>
                  <li>Reached 5,000,000 Social Interactions</li>
                </ul>
              </div>
            </div>
          ) : (
            <div className="roadmap-card  card3">
              <h3>Phase 3: 2023-2025</h3>
              <div className="roadmap-list">
                <ul>
                  <li>Partnered with 65+ college organizations</li>
                  <li>Participate in BASE Buildathon</li>
                  <li>Developed/Integrated Smart contracts</li>
                  <li>Smart Contract Audits</li>
                  <li>Launch on BASE Mainnet</li>
                </ul>
              </div>
            </div>
          )}
          <div className="roadmap-card card4">
            <h3>Phase 4: 2025-2027</h3>
            <div className="roadmap-list">
              <ul>
                <li>Partner with 100+ college organizations</li>
                <li>Partner with festivals, events and universities</li>
                <li>Onboard retail proximity advertising partners</li>
                <li>Release Protocol to allow other Dapps to build on
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Roadmap;
