import React from "react";
import "./Problem.css";
import Prob0 from "../../assets/img/problem0.png";
const Problem = ({ buyTokenRef }) => {

  const scrollToSection = () => {
    const section = document.getElementById('buy-tokens');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div
      className="main-banner container-width"
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <div className="banner-text">
        <h1>
        Meeting People is Hard
        </h1>
        <p>
        Despite being more “connected” now than ever, social media and 
        technological advancements have left us feeling more isolated. 
        The US Surgeon General's 2023 Advisory highlights the severe health impacts 
        of loneliness. Humans crave in-person interaction. But starting conversations 
        can be tough due to fear of rejection, shyness, or feeling out of place.
        </p>
      </div>
      <div className="banner-img">
        <img className="blue-image-1" src={Prob0} alt="" />
      </div>
    </div>
  );
};

export default Problem;
