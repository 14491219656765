import React, { useEffect, useState } from "react"
import ThirdwebPay from "../ThirdwebPay"
import "./style.css"
import { TOKEN_ADDRESS, TOKEN_SUPPLY, WALLET_TO_TRACK } from "../../CONSTANTS"
import { useContract, useTokenBalance } from "@thirdweb-dev/react"
import "../PublicSale/PublicSale.css";

export default function BuyBlueToken() {

    // const [isUniswap, setIsUniswap] = useState(false)
    // const [currentTokenSupply, setCurrentTokenSupply] = useState([])
    // const [percentSold, setPercentSold] = useState()

    // const { contract } = useContract(TOKEN_ADDRESS, "token");
    // const { data, isFetched } = useTokenBalance(contract, WALLET_TO_TRACK);

    // useEffect(() => {
    //     if (isFetched) {
    //         const tokensPurchased = Number(data.displayValue)
    //         const percentSold = ((tokensPurchased / TOKEN_SUPPLY) * 100).toFixed(2);
    //         const formattedSupply = (tokensPurchased).toLocaleString('en-US');
    //         const currentTokenSupply = Array.from(formattedSupply);
    //         setPercentSold(percentSold)
    //         setCurrentTokenSupply(currentTokenSupply)
    //     }
    // }, [isFetched, data])

    return (
        <div id="buy-token" className="buy-token">
            <h2>BUY <span>BLUE</span> TOKENS</h2>
            {/* <div className="num-main">
                { 
                    isFetched &&
                        <>
                            <div className="number-flex">
                                <div className="num-sec">
                                    {
                                        currentTokenSupply.map((item, i) => (
                                            <React.Fragment key={i}>
                                                {
                                                    item === ',' ? (
                                                        <p className='coma'>,</p>
                                                    ) : (
                                                        <p className="single-number">{item}</p>
                                                    )
                                                }
                                            </React.Fragment>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="loading-bar-container">
                                <div className="loading-bar" style={{ width: `${percentSold}%` }}></div>
                            </div>
                        </>
                }
                <p>
                    <span className="blue-text">{percentSold}%</span> of Blue Social Tokens remaining for purchase
                </p>
            </div>  */}
            {/* <div className="switch-container">
                <div className="switch">
                    <button
                        onClick={() => setIsUniswap(false)} 
                        className={!isUniswap && "active"}>
                        Buy with Credit Card
                    </button>
                    <button 
                        onClick={() => setIsUniswap(true)} 
                        className={isUniswap && "active"}>
                        Uniswap
                    </button>
                </div> 
            </div> */}
            {/* {
                isUniswap ? <PublicSale /> : <ThirdwebPay />
            } */}
            <p style={{marginTop: "20px"}}></p>
            <ThirdwebPay />
            <p className="disclaimer">
                <span>Disclaimer</span> : Only interact with this Layer 2 token contract address: 
                <a rel="noreferrer" href="https://basescan.org/token/0x744952C666990dDba159387a9B9e668e4e72682E" target="_blank" className="token"> {TOKEN_ADDRESS} </a> <br/>
                Copy and paste address into the above section to find the official BLUE token. <br/>
                All other tokens may be possible scams. Please be aware.
            </p>

        </div>
    )
}