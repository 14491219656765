import React from 'react';
import './Platfroms.css';
import Draper from '../../assets/img/draper.png';
import NewYork from '../../assets/img/nytimes.png';
import Forbes from '../../assets/img/forbes.png';
import Yahoo from '../../assets/img/yahoo.png';
import Uni from '../../assets/img/univision.png';
import Abc from '../../assets/img/abc.png';
import MDP from '../../assets/img/MeetDrapers.png';
import repub from '../../assets/img/republic.png';
import onChainSummer from '../../assets/img/OnChain_Summer.png';
import thirdWebPlatform from '../../assets/img/ThirdwebPlatform.png';
import superChain from '../../assets/img/Superchain.png';
import winnerBadge from "../../assets/img/winner-badge.png"

const Platfroms = () => {
  const logos = [
    {
      src: onChainSummer,
      alt: 'OnChain Summer',
      link: 'https://devfolio.co/projects/blue-social-a74f',
    },
    {
      src: Draper,
      alt: 'Draper',
      link: 'https://www.draper.vc/companies/blue',
    },
    { src: NewYork, alt: 'New York Times', link: '' },
    { src: Forbes, alt: 'Forbes', link: '' },
    {
      src: Yahoo,
      alt: 'Yahoo',
      link: 'https://finance.yahoo.com/news/billionaire-tim-draper-invests-1-183000432.html',
    },
    { src: Uni, alt: 'Univision', link: '' },
    { src: Abc, alt: 'ABC', link: '' },
    {
      src: MDP,
      alt: 'MeetTheDrapers',
      link: 'https://www.meetthedrapers.com/season3video',
    },
    { src: repub, alt: 'Republic', link: 'https://republic.co/blue' },
    {
      src: thirdWebPlatform,
      alt: 'ThirdWebPlatform',
      link: 'https://www.thirdweb.com/',
    },
    {
      src: superChain,
      alt: 'Optimism SuperChain',
      link: 'https://www.optimism.io/',
    },
  ];

  return (
    <div className='section-space text-center scroll-section'>
      <h1 className='platfrom-heading '>As seen on</h1>
      <div
        style={{justifyContent: "center", display: "flex"}}
        className="container-width"
        data-aos="fade-in"
        data-aos-duration="1000">
        <a href="https://base.mirror.xyz/p_A3ZxaBUEcpWwTf9_Yg-jnlOro8m8Ypc8LEDnA_ZRk" target="_blank" className="we-won">
          <div><img height={42} width={28} src={winnerBadge} /></div>
          <p>GAMING with thirdweb: 🏆 Base Buildathon 2024 Winner </p>
        </a>
      </div>
      <div className='social-flex'>
        <div className='scroll-content'>
          {logos.map((logo, index) => (
            <a
              key={index}
              href={logo.link}
              className='single-social'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src={logo.src} alt={logo.alt} />
            </a>
          ))}
          {logos.map((logo, index) => (
            <a
              key={index + logos.length}
              href={logo.link}
              className='single-social'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src={logo.src} alt={logo.alt} />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Platfroms;
