import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import HomePage from "./HomePage";
import EarlyAccess from "./early-access";
import DiscordPage from "./early-access/discord-page";


const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "early-access",
    element: <EarlyAccess />,
  },
  {
    path: "early-access/discord",
    element: <DiscordPage />,
  },
]);


function App() {

  return (
    <RouterProvider router={router} />
  );
}

export default App;
