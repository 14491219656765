import React from "react";
import TeamBg from "../../assets/img/team-bg.png";
import Team1 from "../../assets/img/JoseJr.png";
import Josh from "../../assets/img/Joshua.png";
import Team3 from "../../assets/img/JoseSr.png";
import POL from "../../assets/img/Paul.png";
import Javi from "../../assets/img/Javier.png";
import Joe from "../../assets/img/Joe.png";
import Himen from "../../assets/img/Himen.png";
import Ethan from "../../assets/img/Ethan.png";
import Will from "../../assets/img/William.png";
import Hello from "../../assets/img/helloBlue2.png";
import "./Team.css";
const Team = () => {
  return (
    <div className="section-space container-width">
      <div className="team-bg">
        <img className="team-bg-img" src={TeamBg} alt="" />
        <h1>
          By The People <br />
          For The People
        </h1>
      </div>
      <div className="team-flex">
        <div className="single-team">
          <img className="single-team-img" src={Team1} alt="" />
          <a text-decoration="none" href="https://www.profiles.blue/Jose_Montero">
            <div class="bluBlok">
              <img className="mini-img" src={Hello} alt="" />
              <p>View Blue Profile</p>
            </div>
          </a>
        </div>
        <div className="single-team">
          <img className="single-team-img" src={Team3} alt="" />
          <a text-decoration="none" href="https://www.profiles.blue/jose-sr---generic-profile">
            <div class="bluBlok">
              <img className="mini-img" src={Hello} alt="" />
              <p>View Blue Profile</p>
            </div>
          </a>
        </div>
        <div className="single-team">
          <img className="single-team-img" src={Himen} alt="" />
          <a text-decoration="none" href="https://www.profiles.blue/profile/h8mqguf702">
            <div class="bluBlok">
              <img className="mini-img" src={Hello} alt="" />
              <p>View Blue Profile</p>
            </div>
          </a>
        </div>
        <div className="single-team">
          <img className="single-team-img" src={Javi} alt="" />
          <a text-decoration="none" href="https://www.profiles.blue/Xthemadgenius">
            <div className="bluBlok">
              <img className="mini-img" src={Hello} alt="" />
              <p>View Blue Profile</p>
            </div>
          </a>
        </div>
      </div>
        <div className="team-flex">
        <div className="single-team">
          <img className="single-team-img" src={Joe} alt="" />
          <a text-decoration="none" href="https://www.profiles.blue/profile/punmugqi9z">
            <div class="bluBlok">
              <img className="mini-img" src={Hello} alt="" />
              <p>View Blue Profile</p>
            </div>
          </a>
        </div>
        <div className="single-team">
          <img className="single-team-img" src={Will} alt="" />
          <a text-decoration="none" href="https://www.profiles.blue/profile/zplvynaecm">
            <div class="bluBlok">
              <img className="mini-img" src={Hello} alt="" />
              <p>View Blue Profile</p>
            </div>
          </a>
        </div>
        <div className="single-team">
          <img className="single-team-img" src={Ethan} alt="" />
          <a text-decoration="none" href="https://www.profiles.blue/ethan-santos-54">
            <div class="bluBlok">
              <img className="mini-img" src={Hello} alt="" />
              <p>View Blue Profile</p>
            </div>
          </a>
        </div>
      </div>
      <div className="team-flex">
        <div className="single-team">
          <img className="single-team-img" src={Josh} alt="" />
          <a text-decoration="none" href="https://www.profiles.blue/profile/sohmzxcrsi">
            <div class="bluBlok">
              <img className="mini-img" src={Hello} alt="" />
              <p>View Blue Profile</p>
            </div>
          </a>
        </div>
        <div className="single-team">
          <img className="single-team-img" src={POL} alt="" />
          <a text-decoration="none !important" href="https://web3.blue.social">
            <div class="bluBlok">
              <img className="mini-img" src={Hello} alt="" />
              <p>View Blue Profile</p>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Team;
