import { useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

export default function DiscordPage () {

    const navigate = useNavigate()
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const id = searchParams.get('id');
        const username = searchParams.get('username');
        const email = searchParams.get('email');
        localStorage.setItem("discord", JSON.stringify({id, username, email}))
        navigate("/early-access")
    }, [searchParams])

    return (<div></div>)
}